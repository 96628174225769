import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import api from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Input, Select } from '../Common';

const CreateFacilityModal = ({ open, setOpen, loadCustomers, filter }) => {
    const priceAreas = [
        { value: 'none', label: 'Price Area' },
        { value: 'SE1', label: 'SE1' },
        { value: 'SE2', label: 'SE2' },
        { value: 'SE3', label: 'SE3' },
        { value: 'SE4', label: 'SE4' },
        { value: 'AT', label: 'AT' },
    ];

    const secondsInDay = 24 * 3600;
    const linkToWebApp = [
        { label: 'Link to Web App', value: 0 },
        { label: '1 Day', value: secondsInDay },
        { label: '1 Week', value: 7 * secondsInDay },
        { label: '1 Month', value: 30 * secondsInDay },
        { label: '3 Months', value: 3 * 30 * secondsInDay },
    ];

    const [customerNumber, setCustomerNumber] = useState();
    const [facilityNumber, setFacilityNumber] = useState();
    const [address, setAddress] = useState();
    const [priceArea, setPriceArea] = useState(priceAreas[0].value);
    const [duration, setDuration] = useState('');
    const [showLink, setShowLink] = useState(false);
    const [link, setLink] = useState('');
    const [error, setError] = useState('');

    const createFacility = async () => {
        setError('');
        try {
            if (priceArea === 'none' || !customerNumber || !facilityNumber || !address) {
                setError('You must enter customer and facility numbers, as well as address and price area!');
            } else {
                await api.create_facility(customerNumber, facilityNumber, address, priceArea);
                if (duration === '') {
                    setLink((await api.customer_web_app_link(customerNumber, 30 * secondsInDay, 'v2')).data.link);
                    setShowLink(true);
                } else if (parseInt(duration) !== 0) {
                    setLink((await api.customer_web_app_link(customerNumber, duration, 'v2')).data.link);
                    setShowLink(true);
                } else {
                    toast.success(`Facility created successfully`, {
                        position: 'top-center',
                        autoClose: 300,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        onClose: handleClose(),
                    });
                }
            }
        } catch {
            setError('An error happened during facility creation!');
        }
    };

    const handleClose = () => {
        setOpen(false);
        setLink('');
        setShowLink(false);
        setError('');
        setPriceArea();
        setDuration('None');

        loadCustomers(filter);
    };

    return (
        <Popup open={open} onClose={handleClose} modal className="modal">
            <div>
                <ToastContainer />
                <button className="modal__close" onClick={handleClose}>
                    &#10005;
                </button>
                <h6 className="modal__header"> Create facility </h6>
                <div className="modal__content">
                    <Input type="text" placeholder="Customer Number" onChange={e => setCustomerNumber(e.target.value)} fullWidth />
                    <Input type="text" placeholder="Facility Number" onChange={e => setFacilityNumber(e.target.value)} fullWidth />
                    <Input type="text" placeholder="Address" onChange={e => setAddress(e.target.value)} fullWidth />

                    <Select value={priceArea} defaultValue="" onChange={e => setPriceArea(e.target.value)} options={priceAreas} fullWidth />
                    <Select value={duration} onChange={e => setDuration(e.target.value)} options={linkToWebApp} fullWidth />

                    {showLink && (
                        <div className="create-facility__success">
                            <div className="create-facility__success-message">
                                Your facility was successfully created! Please copy the link now because you won't be able to get it again!
                            </div>
                            <div className="create-facility__input-copy">
                                <a href={link} className="create-facility__link">
                                    {link.substring(0, 45)}...
                                </a>
                                <button className="create-facility__icon" onClick={() => navigator.clipboard.writeText(link)}>
                                    <img src="/assets/copy-icon.svg" title="Click to Copy" alt="Copy" />
                                </button>
                            </div>
                        </div>
                    )}

                    {error !== '' && <p className="create-facility__error-message">{error}</p>}
                </div>
                {!showLink && (
                    <div className="modal__actions">
                        <Button text="Create" onClick={createFacility} />
                        <Button className="modal__cancel" text="Cancel" onClick={handleClose} />
                    </div>
                )}
            </div>
        </Popup>
    );
};

export default CreateFacilityModal;
