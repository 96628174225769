import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ColorPicker, createColor } from 'material-ui-color';
import api from '../../api';
import { Button, Input } from '../../components/Common';

const themeDefaults = {
    page_title: 'Your Devices and Savings',
    regular_font: '',
    bold_font: '',
    menu_bar_color: 'orange',
    menu_text_color: 'grey',
    menu_active_text_color: 'white',
    title_color: 'orange',
    button_color: 'orange',
    button_text_color: 'white',
    pie_highlight_color: 'orange',
    pie_neutral_color: 'grey',
};

const themeOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

function ensureColorObjects(settings) {
    let result = {};
    for (let key in settings) {
        if (key.includes('color') && typeof settings[key] === 'string') {
            result[key] = createColor(settings[key]);
        } else {
            result[key] = settings[key];
        }
    }
    return result;
}

const WebAppThemeEditor = () => {
    const [theme, setTheme] = useState(ensureColorObjects(themeDefaults));

    useEffect(() => {
        try {
            loadTheme();
        } catch (error) {
            toast.error('Failed to load web application theme settings', themeOptions);
        }
    }, []);

    const loadTheme = async () => {
        let loadedTheme = (await api.web_app_settings()).data || {};
        setTheme(ensureColorObjects({ ...themeDefaults, ...loadedTheme }));
    };

    const saveTheme = async e => {
        e.preventDefault();
        try {
            let newTheme = { ...theme };
            for (let key in newTheme) {
                if (key.includes('color')) {
                    newTheme[key] = '#' + newTheme[key].hex;
                }
            }
            await api.set_web_app_settings(newTheme);
            toast.success('Web application theme settings saved', themeOptions);
        } catch (error) {
            toast.error('Failed to save web application theme settings', themeOptions);
        }
    };

    return (
        <div>
            <ToastContainer />
            <form onSubmit={e => saveTheme(e)}>
                <table>
                    <tbody>
                        <tr>
                            {<TextSetting id="page_title" description="Page Title" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="menu_bar_color" description="Menu Background" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="title_color" description="Section" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="pie_highlight_color" description="Pie Graph Highlight" theme={theme} setTheme={setTheme} />}
                        </tr>
                        <tr>
                            {<FileSetting id="favicon" description="Icon (favicon) File" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="menu_text_color" description="Menu Text" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="button_color" description="Button Background" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="pie_neutral_color" description="Pie Graph Neutral" theme={theme} setTheme={setTheme} />}
                        </tr>
                        <tr>
                            {<TextSetting id="regular_font" description="Regular Font Family" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="menu_active_text_color" description="Menu Active" theme={theme} setTheme={setTheme} />}
                            {<ColorSetting id="button_text_color" description="Button Text" theme={theme} setTheme={setTheme} />}
                        </tr>
                        <tr>{<FileSetting id="regular_font_file" description="Regular Font File (Optional)" theme={theme} setTheme={setTheme} />}</tr>
                        <tr>{<TextSetting id="bold_font" description="Bold Font Family" theme={theme} setTheme={setTheme} />}</tr>
                        <tr>{<FileSetting id="bold_font_file" description="Bold Font File (Optional)" theme={theme} setTheme={setTheme} />}</tr>
                        <tr>
                            <td>
                                <Button className="customer-web-view__submit" isSubmit text="Save" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
};

const TextSetting = ({ id, description, theme, setTheme }) => {
    return (
        <>
            <td>
                {description}:
                <br />
                <Input fullWidth type="text" value={theme[id]} onChange={e => setTheme({ ...theme, [id]: e.target.value })} />
            </td>
        </>
    );
};

const FileSetting = ({ id, description, theme, setTheme }) => {
    return (
        <>
            <td>
                {description}:
                <label className="default-input customer-web-view__file">
                    <input
                        type="file"
                        onChange={e => {
                            let file = e.target.files[0];
                            let reader = new FileReader();
                            reader.addEventListener('load', done => {
                                let bytes = new Uint8Array(done.target.result);
                                let binary = '';
                                let len = bytes.byteLength;
                                for (let i = 0; i < len; i++) {
                                    binary += String.fromCharCode(bytes[i]);
                                }
                                let base64 = window.btoa(binary);
                                setTheme({ ...theme, [id]: base64 });
                            });
                            reader.readAsArrayBuffer(file);
                        }}
                    />
                    Choose File
                </label>
            </td>
        </>
    );
};

const ColorSetting = ({ id, description, theme, setTheme }) => {
    return (
        <>
            <td>
                {description}:
                <br />
                <ColorPicker
                    value={theme[id]}
                    onChange={e =>
                        setTheme({
                            ...theme,
                            [id]: typeof e === 'string' ? createColor(e) : e,
                        })
                    }
                />
            </td>
        </>
    );
};

export default WebAppThemeEditor;
