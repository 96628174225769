import { useEffect, useState } from 'react';
import { loadImage } from '@emulate/shared-images';
import { showComment } from '../../containers/RetailerAPI/SelectBrands';
import InfoTooltip from '../InfoTooltip/InfoTooltip';

const ManufacturerItem = ({ manufacturer, selectedIds, handleSelect }) => {
    const [logoUrl, setLogoUrl] = useState(undefined);

    useEffect(() => {
        let fileName = manufacturer.id;
        const fetchImage = async () => {
            if (fileName.includes('own_') || fileName.includes('_old')) {
                fileName = fileName.replace(/own_|_old/g, '');
            }

            const url = await loadImage(`${fileName}.png`);
            setLogoUrl(url);
        };

        fetchImage();
    }, [manufacturer.id]);

    return (
        <div className="select-manufacturers__brand-item" key={manufacturer.id}>
            <div className="select-manufacturers__brand-item--select-brand-checkbox">
                <input
                    type="checkbox"
                    name="brand"
                    id={`brand-${manufacturer.id}`}
                    checked={selectedIds.includes(manufacturer.id)}
                    onChange={() => handleSelect(manufacturer.id)}
                />
                <label htmlFor={`brand-${manufacturer.id}`}>{manufacturer.label.toUpperCase()}</label>
                {manufacturer.state !== 'stable' && manufacturer.comment && manufacturer.comment !== '' && (
                    <InfoTooltip tooltip={showComment(manufacturer)} />
                )}
            </div>
            <img className="select-manufacturers__brand-item--brand-logo" src={logoUrl} alt={manufacturer.label} />
        </div>
    );
};

export default ManufacturerItem;
